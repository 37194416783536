import React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <>
      <h1>Page not found</h1>
      <Link to="/">Go home</Link>
    </>
  );
};

export default NotFoundPage;
